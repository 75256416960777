<template>
    <div class="home">
        <p></p>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name       : 'Home',
    components : {}
};

</script>
